import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import CustomTabs from "components/CustomTabs/CustomTabs.jsx"
import Carousel from "react-slick"
import Card from "components/Card/Card.jsx"
import Button from "components/CustomButtons/Button.jsx"

import defaultSectionStyle from "assets/jss/finwe/defaultSectionStyle.jsx"

import image1 from "assets/img/products/varre/varre1.jpg"
import image2 from "assets/img/products/varre/varre2.jpg"
import image3 from "assets/img/products/varre/varre3.jpg"

import FinCloud from "assets/img/logos/fincloud-480x480.png"
import SESP from "assets/img/logos/sesp-480x480.png"

class OtherProductsSection extends React.Component {
  render() {
    const { classes } = this.props
    const carouselSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    }
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <div id="SectionContent">
            <div className={classes.title}>
              <h2>
                <a href="https://www.varre.io/">Varre.io</a>
              </h2>
              <h4>Information Management System for Event Security</h4>
              <br />
            </div>

            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "Screenshots",
                  tabIcon: undefined,
                  tabContent: (
                    <div className={classes.section}>
                      <div className={classes.container}>
                        <GridContainer>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            className={classes.marginAuto}
                          >
                            <Card carousel>
                              <Carousel {...carouselSettings}>
                                <div>
                                  <img
                                    src={image1}
                                    alt="Slide"
                                    className="slick-image"
                                  />
                                </div>
                                <div>
                                  <img
                                    src={image2}
                                    alt="Slide"
                                    className="slick-image"
                                  />
                                </div>
                                <div>
                                  <img
                                    src={image3}
                                    alt="Slide"
                                    className="slick-image"
                                  />
                                </div>
                              </Carousel>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </div>
                    </div>
                  ),
                },
                {
                  tabName: "More Info",
                  tabIcon: undefined,
                  tabContent: (
                    <GridContainer>
                      <div className={classes.typo}>
                        <p>
                          In co-operation with long term event security
                          professionals from SESP, Finwe has developed an event
                          security information management system called Varre.
                        </p>
                        <p>
                          Varre contains a backend system for event security
                          management data and native mobile apps for iOS and
                          Android. The system is designed to be used by event
                          security professionals, such as security guards,
                          security managers, and event organizers. Varre scales
                          well from small events to large festivals with
                          hundreds of security professionals. An
                          example is QStock, a large music festival in Oulu,
                          Finland, who has been using Varre for several years.
                        </p>
                        <p>
                          User interface has been designed to be easy to use and
                          fast to learn, task-oriented and intuitive. The system
                          is designed to be used in real-time, so that all
                          security professionals can see the same information at
                          the same time. It also supports offline use: 
                          the system can be temporarily used even in areas
                          without network coverage.
                        </p>
                        <p>
                          With Varre, it is easy to stay on track with the event
                          security situation, to manage security personnel, to
                          communicate with other security professionals, and to
                          report incidents and mandatory information about the
                          event to the customer and to the authorities, as required by law.
                        </p>
                        <p>
                          <b>
                            Learn more about Varre on its own website{" "}
                            <a href="https://www.varre.io/">Varre.io</a>, or
                            contact us for more information.
                          </b>
                        </p>
                        <GridContainer>
                          <GridItem
                            xs={4}
                            sm={3}
                            md={2}
                            className={
                              (classes.marginAuto, classes.beforeTitle)
                            }
                          >
                            <h4>In co-operation:</h4>
                          </GridItem>
                          <GridItem
                            xs={4}
                            sm={3}
                            md={2}
                            className={classes.marginAuto}
                          >
                            <img
                              src={SESP}
                              alt="SESP"
                              className={
                                classes.imgRounded + " " + classes.imgFluid
                              }
                            />
                          </GridItem>
                          <GridItem
                            xs={4}
                            sm={3}
                            md={2}
                            className={classes.marginAuto}
                          >
                            <img
                              src={FinCloud}
                              alt="FinCloud"
                              className={
                                classes.imgRounded + " " + classes.imgFluid
                              }
                            />
                          </GridItem>
                        </GridContainer>
                      </div>
                    </GridContainer>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(defaultSectionStyle)(OtherProductsSection)
